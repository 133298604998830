<template>
  <base-material-card
    icon="mdi-clipboard-text"
    :title="$t('menu.transaction.list')"
    class="px-5 py-3"
  >
    <!--    <base-add-button :permissions="permissions" :basePath="basePath" :title="$t('transaction.add')">-->
    <!--    </base-add-button>-->
    <div class="mt-2">{{ $t('transaction.viewTotal') }}: {{ meta.total }}</div>
    <div class="d-flex">
      <base-dropdown-filter
        :items="statusFilters"
        @changeFilter="changeStatusFilter"
        style="flex: 0 0 75%; max-width: 75%"
        label="Status Transaksi"
      >
      </base-dropdown-filter>
      <base-dropdown-filter
        :items="reviewFilters"
        @changeFilter="changeReviewFilter"
        style="flex: 0 0 25%; max-width: 25%"
        label="Status Review"
      >
      </base-dropdown-filter>
    </div>
    <v-simple-table v-if="items && items.length > 0">
      <thead>
        <tr>
          <th class="primary--text">No.</th>
          <th class="primary--text">
            {{ $t('transaction.transactionNumber') }}
          </th>
          <th class="primary--text">
            {{ $t('transaction.agentName') }}
          </th>
          <th class="primary--text">
            {{ $t('transaction.clientName') }}
          </th>
          <th class="text-center primary--text">
            {{ $t('general.openDetail') }}
          </th>
        </tr>
      </thead>

      <tbody>
        <tr v-for="(item, index) in items" :key="`transaction-${index}`">
          <td>{{ index + 1 }}</td>
          <td>{{ item.transaction_number }}</td>
          <td>{{ item.agent_name }}</td>
          <td>{{ item.payer_client_name }}</td>
          <td>
            <v-row class="justify-center">
              <v-col cols="auto">
                <base-detail-button
                  :permissions="permissions"
                  :basePath="basePathAgent"
                  :id="item.agent_uuid"
                  :buttonText="$t('agent.btn.viewDetail')"
                ></base-detail-button>
              </v-col>
              <v-col cols="auto">
                <base-detail-button
                  :permissions="permissions"
                  :basePath="basePath"
                  :id="item.uuid"
                ></base-detail-button>
              </v-col>
            </v-row>
          </td>
        </tr>
      </tbody>
    </v-simple-table>
    <pagination
      :push-state="false"
      :meta="meta"
      @changePage="changePage"
      :maxVisibleButtons="5"
      v-if="meta"
    />
  </base-material-card>
</template>

<script>
import { mapState } from 'vuex';
import HelperMixin from '@/mixins/helpers';
import { format } from 'date-fns';
const Pagination = () => import('@/components/utils/fractal-pagination');

export default {
  mixins: [HelperMixin],
  props: ['items', 'meta', 'permissions'],
  components: {
    Pagination,
  },
  data() {
    return {
      format,
    };
  },
  computed: {
    ...mapState({
      basePath: (state) => state.transaction.basePath,
      basePathAgent: (state) => state.belirumaUser.basePath,
      statusFilters: (state) => state.transaction.statusFilters,
      reviewFilters: (state) => state.transaction.reviewFilters,
    }),
  },
  methods: {
    changePage(page) {
      this.$emit('changePage', page);
    },
    changeStatusFilter(val) {
      this.$emit('changeStatusFilter', val);
    },
    changeReviewFilter(val) {
      this.$emit('changeReviewFilter', val);
    },
  },
};
</script>
